/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useMemo, useContext, useCallback } from 'react';
import { Grid, makeStyles, Paper, Button, Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, Prompt, useHistory } from 'react-router-dom';
import isEmpty from '../../../utils/isEmpty';
import {
  setActualReunion,
  updateReunion,
  updateReunionPointInfo,
  getPointSpeakTime,
  getPeriodSpeakTime,
  updateReunionPointDeclaration,
  getInterventionsSpeakTime,
  updatePublicDiscussionTimer,
  updateBeforeDayOrderTimer,
  updatePointDiscussionTimer,
  updateMeetingTimer,
} from '../../../store/actions/reunionsActions';
import {
  updateMeetingPointStatus,
  endMeeting,
  updateMeetingSecretary,
} from '../../../store/actions/meetingsActions';
import useInterval from '../../../utils/hooks/useInterval';
import Body2 from '../../common/typographys/Body2';
import Title from './pieces/activeMeeting/Title';
import ThemesAndPoints from './pieces/activeMeeting/ThemesAndPoints';
import ActiveProposal from './pieces/activeMeeting/ActiveProposal';
import ViewPoint from './pieces/activeMeeting/ViewPoint';
import NoActiveProposal from './pieces/activeMeeting/NoActiveProposal';
import DialogCheck from '../../common/dialogs/DialogCheck';
import useBooleanToggle from '../../../utils/hooks/useBooleanToggle';
import { SocketReunion } from './SocketReunionWrapper';
import PropTypes from 'prop-types';
import { MeetingCommon } from './MeetingCommonWrapper';
import { removeReunion } from '../../../store/actions/socketActions';
import GroupUsersTimer from './pieces/activeMeeting/GroupUsersTimer';
import IndividualUsersTimer from './pieces/activeMeeting/IndividualUsersTimer';
import DialogVoteType from './pieces/activeMeeting/DialogVoteType';
import SecretVotesCounter from './pieces/activeMeeting/SecretVotesCounter';
import clsx from 'clsx';
import Subtitle1 from '../../common/typographys/Subtitle1';
import DialogPointStatus from './pieces/activeMeeting/DialogPointStatus';
import BoxUpdatePointStatus from './pieces/activeMeeting/BoxUpdatePointStatus';
import ProposalStatusInfo from './pieces/activeMeeting/ProposalStatusInfo';
import Subtitle2 from '../../common/typographys/Subtitle2';
import { ReactComponent as AgainstVoteIcon } from '../../../assets/images/againstNoVote.svg';
import { ReactComponent as FavorVoteIcon } from '../../../assets/images/favorNoVote.svg';
import { ReactComponent as AbstentionVoteIcon } from '../../../assets/images/abstentionNoVote.svg';
import { ReactComponent as NoVoteIcon } from '../../../assets/images/noVote.svg';
import PublicVoteDisplay from './pieces/activeMeeting/PublicVoteDisplay';
import replaceItemFromStorage from '../../../utils/replaceItemFromStorage';
import PublicDiscussionTimer from './pieces/activeMeeting/PublicDiscussionTimer';
import flatten from 'lodash/flatten';
import UsersDeclaration from './pieces/activeMeeting/UsersDeclaration';
import QualityVoteContent from './pieces/QualityVoteContent';
import GroupInterventions from './pieces/activeMeeting/GroupInterventions';
import IndividualInterventions from './pieces/activeMeeting/IndividualInterventions';
import Creatable from '../../common/forms/Creatable';
import TimeTracker from './pieces/activeMeeting/TimeTracker';

export const getTotalSecretVotes = secretVotesCount => {
  const keys = Object.keys(secretVotesCount);
  const result = keys.reduce((acc, cur) => {
    const val = acc + (parseInt(secretVotesCount[cur], 10) || 0);
    return val;
  }, 0);

  return result;
};

const getGroupId = (userId, users, isGroup) => {
  if (isGroup) {
    const objUser = users.find(x => x.id === userId) || {};

    return objUser.group_id || null;
  }

  return null;
};

const getCurrentSecretary = board => {
  const currentMainSecretary =
    board.secretary.find(secretary => !!secretary.main) || board.secretary[0];

  if (currentMainSecretary) {
    return {
      label: currentMainSecretary.name,
      name: currentMainSecretary.name,
      value: currentMainSecretary.id,
      id: currentMainSecretary.id,
    };
  }

  return null;
};

const useStyles = makeStyles(theme => ({
  labelVoters: {
    marginTop: '15px',
    marginBottom: '10px',
  },
  marginContainers: {
    marginTop: '30px',
  },
  marginHeader: {
    marginBottom: '10px',
  },
  secretLabel: {
    marginBottom: '4px',
  },
  secretSubLabel: {
    width: '100%',
    marginBottom: '14px',
  },
  endVote: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.neutrals[1],
    height: '44px',
    marginTop: '18px',
    '&:hover': {
      backgroundColor: '#A70202',
    },
  },
  voting: {
    opacity: '0.4',
    pointerEvents: 'none',
  },
  errorVoting: {
    marginTop: '4px',
  },
  unanimousVote: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.neutrals[1],
    padding: '6px 0px 2px 0px',
  },
  iconUnanimous: {
    height: '28px',
    width: '28px',
    fill: theme.palette.primary[50],
    '&:hover': {
      cursor: 'pointer',
      '& *': {
        transition: 'fill 0.2s ease',
        fill: theme.palette.neutrals[6],
      },
    },
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  disabled: {
    pointerEvents: 'none',
    opacity: '0.6',
  },
  unanimousBox: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    marginTop: '6px',
  },
  favor: {
    '& *': {
      transition: 'fill 0.2s ease',
      fill: theme.palette.colorsPalette.TrueGreen,
    },
  },
  abstention: {
    '& *': {
      transition: 'fill 0.2s ease',
      fill: theme.palette.colorsPalette.Orange,
    },
  },
  against: {
    '& *': {
      transition: 'fill 0.2s ease',
      fill: theme.palette.colorsPalette.RustRed,
    },
  },
  noVote: {
    '& *': {
      transition: 'fill 0.2s ease',
      fill: theme.palette.colorsPalette.TrueBlue,
    },
  },
  labelHelper: {
    marginTop: '20px',
  },
  cancelVote: {
    backgroundColor: theme.palette.neutrals[5],
    color: theme.palette.neutrals[1],
    height: '44px',
    marginTop: '18px',
    '&:hover': {
      backgroundColor: theme.palette.neutrals[6],
    },
  },
}));

const periods = [
  { type: 'public-discussion', name: 'Intervenção do público' },
  { type: 'before-day-order', name: 'Antes da ordem do dia' },
];

const MeetingModerator = ({ change }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useParams();
  const { id: userId, name, email } = useSelector(state => state.auth.user);

  const users = useSelector(state => state.reunions?.reunion?.users);
  const participants = useSelector(state => state.reunions?.reunion?.participants);
  const is_group = useSelector(
    state => state.reunions?.reunion?.is_group || state.reunions?.reunion?.isGroup
  );
  const isPublic = useSelector(state => state.reunions?.reunion?.is_public);
  const speakers = useSelector(state => state.reunions?.reunion?.speakers);
  const infoSpeaking = useSelector(state => state.reunions?.reunion?.infoSpeaking);
  const speakersInfo = useSelector(state => state.reunions?.reunion?.speakersInfo);
  const currentPublic = useSelector(state => state.reunions?.reunion?.currentPublic || 0);
  const qualityVoters = useSelector(state => state.reunions?.reunion?.qualityVoters || []);
  const interventions = useSelector(state => state.reunions?.reunion?.interventions || 0);
  const points = useSelector(state => state.reunions?.reunion?.points || []);
  const themes = useSelector(state => state.reunions?.reunion?.themes || []);
  const board = useSelector(state => state.reunions?.reunion?.board || {});
  const typology = useSelector(state => state.reunions?.reunion?.typology || {});
  const publicDiscussionTimer = useSelector(
    state => state.reunions?.reunion?.timers?.publicDiscussion || {}
  );
  const beforeDayOrderTimer = useSelector(
    state => state.reunions?.reunion?.timers?.beforeDayOrder || {}
  );
  const meetingTimer = useSelector(state => state.reunions?.reunion?.timers?.meeting || {});
  const discussionPointsTimers = useSelector(
    state => state.reunions?.reunion?.timers?.points || {}
  );
  const reunionActiveInfo = useSelector(state => {
    return state.reunions?.reunion?.activeInfo;
  });
  const sentSocket = useSelector(state => state.reunions?.reunion?.sentSocket);

  const [active, setActive] = useState({});
  const [activeInfo, setActiveInfo] = useState({});
  const [openBegin, toggleOpenBegin] = useBooleanToggle();
  const [openEnd, toggleOpenEnd, setOpenEnd] = useBooleanToggle();
  const [openCancelVote, setOpenCancelVote] = useState(false);
  const [openEndReunion, setOpenEndReunion] = useState(false);
  const [voting, setVoting] = useState(false);
  const [typeVote, setTypeVote] = useState('');
  const [pointStatus, setPointStatus] = useState([]);
  const [openPointStatus, toggleOpenPointStatus] = useBooleanToggle();
  const [openVoteTie, toggleOpenVoteTie] = useBooleanToggle();
  const [secretVotes, setSecretVotes] = useState({ favor: 0, against: 0, abstention: 0, novote: 0 });
  const [votes, setVotes] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const { socketNsp } = useContext(SocketReunion);
  const {
    handleInfo,
    handleImmediateVote,
    handleImmediateDiscussion,
    changePage,
    confirmChange,
    cancelLeaving,
    setSelected,
    selected,
    openWarning,
    reunionEnd,
    setReunionEnd,
    setAuxSelected,
  } = useContext(MeetingCommon);
  const isSelectedPoint = useMemo(
    () =>
      (selected.idxTheme === null && isEmpty(selected.type)) ||
      (selected.idxTheme !== null && selected.idxProposal !== null),
    [selected]
  );
  const isProposal = useMemo(
    () => !isEmpty(activeInfo) && activeInfo.idxProposal !== null,
    [activeInfo]
  );
  const activeHasStatus = useMemo(
    () =>
      active && active.meeting_point_status && active.meeting_point_status.point_status !== null,
    [active]
  );
  const hasDeclaration = useMemo(
    () =>
      active &&
      active.meeting_point_status &&
      active.meeting_point_status.declaration &&
      active.meeting_point_status.declaration.length > 0,
    [active]
  );
  const [allVote, setAllVote] = useState('');
  // ! Voto de qualidade
  const [openQualityVote, setOpenQualityVote] = useState(false);
  const [qualityVoteUser, setQualityVoteUser] = useState('');
  const [qualityVoteType, setQualityVoteType] = useState('');
  const canUseQualityVote = typeVote === 'public' && qualityVoters.length > 0;
  const isMainModerator = useMemo(() => getCurrentSecretary(board).id === userId, [board, userId]);
  const [win, setWin] = useState(null);

  // Auto-starts the timer when entering public discussion.
  useEffect(() => {
    const isPublicDiscussion = active?.type === 'public-discussion';
    const publicDiscussionTimerIsStopped = publicDiscussionTimer.state === 'stop';

    if (isMainModerator && isPublicDiscussion && publicDiscussionTimerIsStopped) {
      const autoStartedTimer = {
        ...publicDiscussionTimer,
        state: 'running',
      };

      dispatch(updatePublicDiscussionTimer(autoStartedTimer));

      socketNsp.emit(
        'update_public_discussion_timer',
        JSON.stringify({
          id,
          publicDiscussion: autoStartedTimer,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active.type]);

  // Stops the previous timer when switching periods.
  useEffect(() => {
    if (!isMainModerator) return;

    switch (active?.type) {
      case 'public-discussion':
        {
          const { state } = beforeDayOrderTimer;

          if (state === 'stop' || state === 'pause') return;

          const pausedTimer = {
            ...beforeDayOrderTimer,
            state: 'pause',
          };

          dispatch(updateBeforeDayOrderTimer(pausedTimer));

          socketNsp.emit(
            'update_before_day_order_timer',
            JSON.stringify({
              id,
              publicDiscussion: pausedTimer,
            })
          );
        }
        break;
      case 'before-day-order':
        {
          const { state } = publicDiscussionTimer;

          if (state === 'stop' || state === 'pause') return;

          const pausedTimer = {
            ...publicDiscussionTimer,
            state: 'pause',
          };

          dispatch(updatePublicDiscussionTimer(pausedTimer));

          socketNsp.emit(
            'update_public_discussion_timer',
            JSON.stringify({
              id,
              publicDiscussion: pausedTimer,
            })
          );
        }
        break;
      default:
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active.type]);

  useInterval(
    () => {
      const timerState = {
        ...meetingTimer,
        value: parseInt(meetingTimer.value, 10) + 1,
      };

      dispatch(updateMeetingTimer(timerState));

      socketNsp.emit(
        'update_meeting_timer',
        JSON.stringify({
          id,
          meeting: timerState,
        })
      );
    },
    !reunionEnd && meetingTimer.state === 'running' && isMainModerator ? 1000 : null
  );

  // Stops proposal discussion and protest timers when the active point changes
  useEffect(() => {
    if (
      !isMainModerator ||
      isEmpty(active) ||
      active.type === 'public-discussion' ||
      active.type === 'before-day-order'
    ) {
      return;
    }

    const pointId = getActiveDiscussionPointTimerId();
    const presentationTimer = getActiveDiscussionPointTimer('presentation');
    const protestsTimer = getActiveDiscussionPointTimer('protests');

    if (presentationTimer.state !== 'stop' && presentationTimer.state !== 'pause') {
      const pausedPresentationTimer = {
        ...presentationTimer,
        state: 'pause',
      };

      dispatch(updatePointDiscussionTimer(pointId, 'presentation', pausedPresentationTimer));

      socketNsp.emit(
        'update_point_discussion_timer',
        JSON.stringify({
          id,
          pointId,
          timerType: 'presentation',
          timerState: pausedPresentationTimer,
        })
      );
    }

    if (protestsTimer.state !== 'stop' && protestsTimer.state !== 'pause') {
      const pausedProtestsTimer = {
        ...protestsTimer,
        state: 'pause',
      };

      dispatch(updatePointDiscussionTimer(pointId, 'protests', pausedProtestsTimer));

      socketNsp.emit(
        'update_point_discussion_timer',
        JSON.stringify({
          id,
          pointId,
          timerType: 'protests',
          timerState: pausedProtestsTimer,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  const participantsInfo = useMemo(() => {
    if (participants && users) {
      const { secretary = [], moderator = [] } = board;
      const flattenBoard = [...secretary, ...moderator];
      const isGroup = Boolean(is_group);
      const meetingParticipants = users.reduce((acc, cur) => {
        if (isGroup) {
          acc.push(
            ...cur.users.filter(x => x.in_meeting).map(user => ({ ...user, group_id: cur.id }))
          );
        } else if (!isGroup && cur.in_meeting) {
          acc.push({ ...cur, group_id: null });
        }

        return acc;
      }, []);

      return participants.reduce(
        (acc, cur) => {
          if (flattenBoard.find(x => x.id.toString() === cur.id.toString()) === undefined) {
            // @ Utilizador não está na board
            const objSpeaker = meetingParticipants.find(
              x => x && x.id && x.id.toString() === cur.id.toString()
            );
            // @ Não é secretário nem moderador
            // TODO: validar se utilizador não está bloqueado
            if (cur.checkIn && objSpeaker !== undefined) {
              acc.voters.push({ ...objSpeaker, ...cur });
            }
          }
          return acc;
        },
        { voters: [], meetingParticipants }
      );
    }
    return [];
  }, [board, is_group, participants, users]);

  const notValidSecretVoting = useMemo(
    () =>
      typeVote === 'secret' && getTotalSecretVotes(secretVotes) > participantsInfo.voters.length,
    [secretVotes, typeVote, participantsInfo.voters]
  );

  const openAudienceWindow = () => {
    if (win) {
      win.close();
    }

    if (id) {
      replaceItemFromStorage('plateia', {
        users,
        participants,
        is_group,
        isPublic,
        speakers,
        infoSpeaking,
        speakersInfo,
        currentPublic,
        qualityVoters,
        interventions,
        points,
        themes,
        board,
        reunionActiveInfo,
        timers: {
          meeting: meetingTimer,
          beforeDayOrder: beforeDayOrderTimer,
          publicDiscussion: publicDiscussionTimer,
        },
      });
      const w = window.open(
        `${location.pathname}/plateia`,
        '_blank',
        'toolbar=0,location=0,menubar=0,width=1280,height=800'
      );
      setWin(w);
    }

    return () => {
      /*if (win) {
        win.close();
      }
      localStorage.removeItem('plateia');*/
    };
  };

  // @ Abre uma janela com vista para a plateia
  useEffect(openAudienceWindow, []);

  useEffect(() => {
    setActiveInfo(currentActiveInfo => {
      if (
        !isEmpty(reunionActiveInfo) &&
        sentSocket &&
        JSON.stringify(currentActiveInfo) !== JSON.stringify(reunionActiveInfo)
      ) {
        return reunionActiveInfo;
      }

      return currentActiveInfo;
    });
  }, [sentSocket, reunionActiveInfo]);

  useEffect(() => {
    if (!isEmpty(activeInfo)) {
      const { idxProposal, idxTheme, type } = activeInfo;
      if (idxProposal !== null && idxTheme !== null) {
        // @ É uma proposta inserida num tema
        const result = themes[idxTheme]?.points[idxProposal] || {};
        setActive(result);
      } else if (idxProposal !== null && idxTheme === null) {
        // @ É uma proposa sem tema
        const result = points[idxProposal] || {};
        setActive(result);
      } else if (idxProposal === null && idxTheme !== null) {
        // @ Tema sem pontos
        const result = themes[idxTheme] || {};
        setActive(result);
      } else if (idxProposal === null && idxTheme === null) {
        // @ É um período
        const period = periods.find(x => x.type === type) || {};
        setActive(period);
      }
    }
  }, [activeInfo, themes, points, reunionActiveInfo]);

  useEffect(() => {
    if (
      !isEmpty(activeInfo) &&
      activeInfo.idxProposal !== undefined &&
      activeInfo.idxTheme !== undefined &&
      JSON.stringify(activeInfo) !== JSON.stringify(reunionActiveInfo)
    ) {
      dispatch(updateReunion({ activeInfo }));
    }
  }, [activeInfo, dispatch, reunionActiveInfo]);

  useEffect(() => {
    const onUpdateActiveHandler = info => {
      setActiveInfo(currentActiveInfo => {
        if (isMainModerator || JSON.stringify(currentActiveInfo) === info) return currentActiveInfo;

        return JSON.parse(info);
      });
    };

    socketNsp.on('update_active', onUpdateActiveHandler);

    return () => {
      socketNsp.off('update_active', onUpdateActiveHandler);
    };
  }, [isMainModerator, socketNsp]);

  useEffect(() => {
    socketNsp.on('reunion_details', info => {
      dispatch(updateReunion(JSON.parse(info)));
    });

    socketNsp.on('list_presences', info => {
      dispatch(updateReunion(JSON.parse(info)));
    });

    socketNsp.on('vote', info => {
      const parsed = JSON.parse(info);
      const { userId: userIdVoted, groupId, vote } = parsed;

      setVotes(c => [
        ...c.filter(x => x.id.toString() !== userIdVoted.toString()),
        { id: userIdVoted, group_id: groupId, vote },
      ]);
    });

    socketNsp.on('reunion_speakers', info => {
      const parsed = JSON.parse(info);

      dispatch(updateReunion({ speakers: parsed }));
    });

    socketNsp.on('current_public_time', info => {
      const parsed = JSON.parse(info);

      dispatch(updateReunion({ currentPublic: parsed }));
    });

    socketNsp.on('current_interventions_time', info => {
      const parsed = JSON.parse(info);

      dispatch(updateReunion({ interventions: parsed }));
    });

    socketNsp.on('reunion_info_speaking', info => {
      const parsed = JSON.parse(info);

      dispatch(updateReunion({ infoSpeaking: parsed }));
    });

    socketNsp.on('reunion_speakers_info', info => {
      const parsed = JSON.parse(info);

      dispatch(updateReunion({ speakersInfo: parsed }));
    });

    socketNsp.on('secretary_update', info => {
      const incoming = JSON.parse(info);

      dispatch(updateReunion(incoming));
    });

    socketNsp.on('reunion_finished', () => {
      setReunionEnd(true);
      dispatch(removeReunion(id));
      return history.push(`/reunioes/consultar/${id}`);
    });

    socketNsp.on('participant_declaration', info => {
      const parsed = JSON.parse(info);
      const infoDeclaration = {
        id: parsed.id,
        type: parsed.type,
        file: parsed.file,
        text: parsed.text,
        groupId: parsed.groupId,
      };

      setActive(infoActive => {
        const { declaration = [] } = infoActive;
        return {
          ...infoActive,
          meeting_point_status: {
            ...infoActive.meeting_point_status,
            declaration: [...declaration, infoDeclaration],
          },
        };
      });
      dispatch(updateReunionPointDeclaration(parsed));
    });

    socketNsp.on('update_meeting_point_status', info => {
      const parsed = JSON.parse(info);
      dispatch(updateReunionPointInfo(parsed));
      setVoting(false);
      setActive(c => ({ ...c, meeting_point_status: parsed.meeting_point_status }));
    });

    socketNsp.on('public_discussion_timer_updated', updatedTimerPayload => {
      const timerState = JSON.parse(updatedTimerPayload).publicDiscussion;

      dispatch(updatePublicDiscussionTimer(timerState));
    });

    socketNsp.on('before_day_order_timer_updated', updatedTimerPayload => {
      const timerState = JSON.parse(updatedTimerPayload).beforeDayOrder;

      dispatch(updateBeforeDayOrderTimer(timerState));
    });

    socketNsp.on('meeting_timer_updated', updatedTimerPayload => {
      const timerState = JSON.parse(updatedTimerPayload).meeting;

      dispatch(updateMeetingTimer(timerState));
    });

    socketNsp.on('point_discussion_timer_updated', updatedTimerPayload => {
      const { pointId, timerType, timerState } = JSON.parse(updatedTimerPayload);

      dispatch(updatePointDiscussionTimer(pointId, timerType, timerState));
    });

    socketNsp.on('update_votes', info => {
      setVotes(current => {
        const incoming = JSON.parse(info);

        if (JSON.stringify(current) !== JSON.stringify(incoming)) {
          return incoming;
        }

        return current;
      });
    });

    socketNsp.emit('join', JSON.stringify({ id, name, userId, email, moderator: true }));

    return () => {
      socketNsp.emit('leave', JSON.stringify({ id, email }));
      socketNsp.removeAllListeners();
      dispatch(setActualReunion({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isMainModerator) return;
    socketNsp.emit('active_info', JSON.stringify({ info: activeInfo, id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInfo, id]);

  useEffect(() => {
    socketNsp.emit('info_speaking', JSON.stringify({ id, infoSpeaking }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoSpeaking, id]);

  useEffect(() => {
    if (!isEmpty(speakers)) {
      socketNsp.emit('speakers', JSON.stringify({ id, speakers }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speakers, id]);

  useEffect(() => {
    if (!isEmpty(currentPublic)) {
      socketNsp.emit('current_public', JSON.stringify({ id, time: currentPublic }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPublic, id]);

  useEffect(() => {
    if (!isEmpty(interventions)) {
      socketNsp.emit('interventions', JSON.stringify({ id, time: interventions }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interventions, id]);

  useEffect(() => {
    socketNsp.emit('update_all_votes', JSON.stringify({ id, votes }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [votes, id]);

  useEffect(() => {
    socketNsp.emit('secret_votes', JSON.stringify({ id, secretVotes }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secretVotes, id]);

  useEffect(() => {
    if (
      isEmpty(speakersInfo) ||
      (!isEmpty(speakersInfo) &&
        !isEmpty(active) &&
        speakersInfo.idxTheme !== undefined &&
        speakersInfo.idxTheme !== activeInfo.idxTheme &&
        speakersInfo.idxProposal !== undefined &&
        speakersInfo.idxProposal !== activeInfo.idxProposal) ||
      (!isEmpty(activeInfo) &&
        (activeInfo.idxTheme !== undefined || activeInfo.idxProposal !== undefined) &&
        JSON.stringify(activeInfo) !== JSON.stringify(reunionActiveInfo))
    ) {
      let speakersValue;

      if (is_group) {
        speakersValue = users.reduce((acc, cur) => {
          if (cur.no_limit || isEmpty(active.matrix)) {
            // @ Sem restrição de tempo OU não existe matrix de tempo
            acc.push({
              ...cur,
              users: cur.users.slice(0, cur.num_users).map(user => ({ ...user, current: 0 })),
              current: 0,
              others: cur.others || 0,
            });
          } else if (!cur.no_limit && !isEmpty(active.matrix)) {
            // @ Restrição de tempo
            if (active.matrix.is_group) {
              const userInMatrix = active.matrix.users.find(x => x.id === cur.id);
              acc.push({
                ...cur,
                ...userInMatrix,
                current: 0,
                others: cur.others || 0,
                users: cur.users.slice(0, cur.num_users).map(user => ({ ...user, current: 0 })),
              });
            } else {
              cur.users.forEach(curUser => {
                const userInMatrix = active.matrix.users.find(x => x.id === curUser.id);

                if (userInMatrix) {
                  acc.push({
                    ...curUser,
                    ...userInMatrix,
                    current: 0,
                  });
                } else {
                  acc.push({
                    ...curUser,
                    current: 0,
                  });
                }
              });
            }
          }

          return acc;
        }, []);
      } else {
        speakersValue = users.map(user => {
          if (isEmpty(active.matrix)) return { ...user, current: 0 };

          const userInMatrix = active.matrix.users.find(x => x.id === user.id);

          return { ...user, ...userInMatrix, current: 0 };
        });
      }

      dispatch(updateReunion({ speakers: speakersValue }));
      dispatch(
        updateReunion({
          speakersInfo: { idxTheme: active.idxTheme, idxProposal: active.idxProposal },
        })
      );

      if (active.id !== undefined) {
        dispatch(getPointSpeakTime(id, active.id));
      } else if (!isEmpty(active.type)) {
        dispatch(getPeriodSpeakTime(id, active.type, active.type === 'public-discussion'));
      }

      dispatch(getInterventionsSpeakTime(id));

      if (isMainModerator) {
        socketNsp.emit('speakers', JSON.stringify({ id, speakers: speakersValue }));
        socketNsp.emit('speakersInfo', JSON.stringify({ id, speakersInfo: activeInfo }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, activeInfo, users, speakersInfo, id, reunionActiveInfo, is_group]);

  useEffect(() => {
    if (qualityVoteUser !== '') {
      const objUserVote = votes.find(x => x.id.toString() === qualityVoteUser.toString()) || {};
      if (!isEmpty(objUserVote)) {
        setQualityVoteType(objUserVote.vote);
      }
    }
  }, [qualityVoteUser, votes]);

  const handleChangeAuxActive = useCallback(
    info => {
      const { idxTheme, idxProposal, type } = info;

      setActive(info);

      const newActiveInfo = {
        idxProposal,
        idxTheme,
        type,
      };

      setActiveInfo(newActiveInfo);

      window.scrollTo(0, 0);

      return setAuxSelected({});
    },
    [setAuxSelected]
  );

  const getNextActiveInfo = useCallback(
    (currentInfo, update) => {
      let obj = currentInfo;
      if (!obj?.type) {
        obj = { type: undefined };
      }
      const themesPoints = flatten(
        themes.map((theme, idxTheme) =>
          theme.points
            .map((point, idxProposal) =>
              point.meeting_point_status && !point.meeting_point_status.discussed
                ? { idxProposal, idxTheme, type: undefined }
                : null
            )
            .filter(Boolean)
        )
      );

      const flatPoints = flatten(
        points
          .map((point, idxProposal) =>
            point.meeting_point_status && !point.meeting_point_status.discussed
              ? { idxProposal, idxTheme: null, type: undefined }
              : null
          )
          .filter(Boolean)
      );

      const filteredPoints = [...themesPoints, ...flatPoints].filter(x =>
        update ? JSON.stringify(x) !== JSON.stringify(obj) : true
      );

      if (filteredPoints.length > 0 && update) {
        const { idxProposal, idxTheme, type } = filteredPoints[0];
        if (idxProposal !== null && idxTheme !== null) {
          // @ É uma proposta inserida num tema
          const result = themes[idxTheme].points[idxProposal] || null;
          handleChangeAuxActive({ ...result, ...filteredPoints[0] });
        } else if (idxProposal !== null && idxTheme === null) {
          // @ É uma proposa sem tema
          const result = points[idxProposal] || null;
          handleChangeAuxActive({ ...result, ...filteredPoints[0] });
        } else if (idxProposal === null && idxTheme !== null) {
          // @ Tema sem pontos
          const result = themes[idxTheme] || null;
          handleChangeAuxActive({ ...result, ...filteredPoints[0] });
        } else if (idxProposal === null && idxTheme === null) {
          // @ É um período
          const period = periods.find(x => x.type === type) || {};
          handleChangeAuxActive({ ...period, ...filteredPoints[0] });
        }
      }

      return filteredPoints;
    },
    [themes, points, handleChangeAuxActive]
  );

  const voteType = useCallback(
    type => e => {
      if (e) e.preventDefault();

      setTypeVote(type);

      if (typology.private_voting) toggleOpenBegin();

      setVoting(true);

      return socketNsp.emit('begin_vote', JSON.stringify({ id, type }));
    },
    [id, socketNsp, toggleOpenBegin, typology.private_voting]
  );

  const beginVote = e => {
    if (e) e.preventDefault();

    setAllVote('');

    if (typology.private_voting) {
      toggleOpenBegin();
    } else {
      voteType('public')();
    }
  };

  const updateSecretVotes = useCallback((type, value) => {
    setSecretVotes(c => ({
      ...c,
      [type]: value,
    }));
  }, []);

  const endReunion = useCallback(e => {
    e.preventDefault();

    setOpenEndReunion(true);
  }, []);

  const confirmEnd = e => {
    e.preventDefault();

    setReunionEnd(true);
    setOpenEndReunion(false);
    dispatch(removeReunion(id));

    const endMeetingPromise = new Promise((resolve, reject) => {
      dispatch(endMeeting(id, resolve, reject));
    });

    return endMeetingPromise
      .then(() => {
        socketNsp.emit('end_reunion', JSON.stringify({ id }));
        return history.push(`/reunioes/consultar/${id}`);
      })
      .catch(() => null);
  };

  const endSecretVote = useCallback(
    e => {
      e.preventDefault();
      setOpenEnd(false);
      setVoting(false);

      const { favor, against, abstention, novote } = secretVotes;
      const { declaration = [] } = active.meeting_point_status;

      let status;

      if (parseInt(favor, 10) > parseInt(against, 10)) {
        status = 1;
      } else if (parseInt(against, 10) > parseInt(favor, 10)) {
        status = 2;
      } else if (parseInt(against, 10) === parseInt(favor, 10)) {
        return toggleOpenVoteTie();
      }

      const meetingPointStatus = {
        discussed: true,
        secret_vote: true,
        secretVote: true,
        total: getTotalSecretVotes(secretVotes),
        favor,
        against,
        abstention,
        novote,
        status,
        votes: [],
        blocked_users: [],
        point_status: status,
        declaration,
      };

      const info = { id, meeting_point_status: meetingPointStatus, infoPoint: activeInfo };

      socketNsp.emit('update_meeting_point', JSON.stringify(info));
      dispatch(
        updateReunionPointInfo({ meeting_point_status: meetingPointStatus, infoPoint: activeInfo })
      );

      const promiseUpdate = new Promise(resolve => {
        dispatch(updateMeetingPointStatus(id, active.id, meetingPointStatus, resolve));
      });

      return promiseUpdate.then(() => {
        setSecretVotes({ favor: 0, against: 0, abstention: 0, novote: 0 });
        setVotes([]);
        setQualityVoteUser('');
        setActive(c => ({ ...c, meeting_point_status: meetingPointStatus }));
        getNextActiveInfo(activeInfo, true);
      });
    },
    [
      active.id,
      active.meeting_point_status,
      activeInfo,
      dispatch,
      getNextActiveInfo,
      id,
      secretVotes,
      setOpenEnd,
      socketNsp,
      toggleOpenVoteTie,
    ]
  );

  const endNormalVote = useCallback(
    e => {
      e.preventDefault();
      setOpenEnd(false);
      setOpenQualityVote(false);
      setVoting(false);

      const { declaration = [] } = active.meeting_point_status;

      const allVotes = participantsInfo.voters.reduce((acc, cur) => {
        const voteObj = votes.find(x => x.id.toString() === cur.id.toString());

        if (voteObj === undefined && cur.checkIn) {
          acc.push({
            id: cur.id,
            group_id: cur.groupId || cur.group_id || null,
            vote: 'abstention',
          });
        } else if (cur.checkIn) {
          acc.push(voteObj);
        }

        return acc;
      }, []);

      const countVotes = allVotes.reduce(
        (acc, cur) => {
          acc[cur.vote] += 1;
          acc.total += 1;

          return acc;
        },
        { favor: 0, against: 0, abstention: 0, novote: 0, total: 0 }
      );

      let newAllVotes = allVotes;

      let { favor, abstention, against, total } = countVotes;
      const { novote } = countVotes;
      let status;

      if (parseInt(favor, 10) > parseInt(against, 10)) {
        status = 1;
      } else if (parseInt(against, 10) > parseInt(favor, 10)) {
        status = 2;
      } else if (parseInt(against, 10) === parseInt(favor, 10) && qualityVoteUser === '') {
        // @ Há empate, e não há id para o utilizador com voto de qualidade
        return toggleOpenVoteTie();
      } else if (parseInt(against, 10) === parseInt(favor, 10) && qualityVoteUser !== '') {
        // @ Há empate MAS foi definido um voto de qualidade
        if (qualityVoteType !== '') {
          // @ Moderador é que atribuiu o voto de qualidade

          if (qualityVoteType === 'favor') {
            const userVoteObj =
              votes.find(x => x.id.toString() === qualityVoteUser.toString()) || null;

            newAllVotes = allVotes.filter(x => x.id.toString() !== qualityVoteUser.toString());
            newAllVotes.push({
              id: qualityVoteUser,
              group_id: userVoteObj
                ? userVoteObj.group_id
                : getGroupId(
                  qualityVoteUser,
                  participantsInfo.meetingParticipants,
                  Boolean(is_group)
                ),
              vote: 'favor',
            });

            if (isEmpty(userVoteObj)) {
              // @ Utilizador não tinha sido contabilizado nos votos
              total += 1;
              favor += 1;
            } else if (userVoteObj.vote !== 'favor') {
              if (userVoteObj.vote === 'against') {
                against -= 1;
              }

              if (userVoteObj.vote === 'abstention') {
                abstention -= 1;
              }

              favor += 1;
            }

            status = 1;
          } else {
            const userVoteObj =
              votes.find(x => x.id.toString() === qualityVoteUser.toString()) || null;

            newAllVotes = allVotes.filter(x => x.id.toString() !== qualityVoteUser.toString());
            newAllVotes.push({
              id: qualityVoteUser,
              group_id: userVoteObj
                ? userVoteObj.group_id
                : getGroupId(
                  qualityVoteUser,
                  participantsInfo.meetingParticipants,
                  Boolean(is_group)
                ),
              vote: 'against',
            });
            if (isEmpty(userVoteObj)) {
              // @ Utilizador não tinha sido contabilizado nos votos
              total += 1;
              against += 1;
            } else if (userVoteObj.vote !== 'against') {
              if (userVoteObj.vote === 'favor') {
                favor -= 1;
              }

              if (userVoteObj.vote === 'abstention') {
                abstention -= 1;
              }

              against += 1;
            }

            status = 2;
          }
        } else {
          const objUserVote = allVotes.find(x => x.id === qualityVoteUser) || {};
          if (objUserVote.vote === 'favor') {
            status = 1;
          } else {
            status = 2;
          }
        }
      }

      // TODO: qualityVoteUser guarda o id do utilizador que efetuou voto de qualidade, confirmar com @pedro.calado como é para enviar isto

      const meetingPointStatus = {
        discussed: true,
        secret_vote: false,
        secretVote: false,
        total,
        favor,
        against,
        abstention,
        novote,
        status,
        votes: newAllVotes,
        blocked_users: [],
        qualityVoteUser,
        point_status: status,
        declaration,
      };

      const info = { id, meeting_point_status: meetingPointStatus, infoPoint: activeInfo };

      socketNsp.emit('update_meeting_point', JSON.stringify(info));
      dispatch(
        updateReunionPointInfo({ meeting_point_status: meetingPointStatus, infoPoint: activeInfo })
      );

      const promiseUpdate = new Promise(resolve => {
        dispatch(updateMeetingPointStatus(id, active.id, meetingPointStatus, resolve));
      });

      return promiseUpdate.then(() => {
        setVoting(false);
        setVotes([]);
        setQualityVoteUser('');
        setQualityVoteType('');
        setActive(c => ({ ...c, meeting_point_status: meetingPointStatus }));
        // getNextActiveInfo(activeInfo, true);
      });
    },
    [
      votes,
      is_group,
      participantsInfo.meetingParticipants,
      qualityVoteType,
      active.id,
      active.meeting_point_status,
      activeInfo,
      participantsInfo.voters,
      toggleOpenVoteTie,
      dispatch,
      id,
      socketNsp,
      setOpenEnd,
      // getNextActiveInfo,
      qualityVoteUser,
    ]
  );

  const confirmRepeat = useCallback(
    e => {
      e.preventDefault();

      toggleOpenVoteTie();

      setSecretVotes({ favor: 0, against: 0, abstention: 0, novote: 0 });
    },
    [toggleOpenVoteTie]
  );

  const confirmCancelVote = useCallback(
    e => {
      e.preventDefault();

      setVoting(false);
      setVotes([]);
      setQualityVoteUser('');
      setOpenCancelVote(false);
      const info = { id };
      socketNsp.emit('cancel_vote', JSON.stringify(info));
    },
    [id, socketNsp]
  );

  const handlePointStatus = useCallback(
    val => e => {
      e.preventDefault();
      setPointStatus(val);
      toggleOpenPointStatus();
    },
    [toggleOpenPointStatus]
  );

  const updatePointStatus = useCallback(
    e => {
      e.preventDefault();
      toggleOpenPointStatus();

      const declaration = active?.meeting_point_status?.declaration || [];

      const meetingPointStatus = {
        discussed: true,
        secret_vote: false,
        secretVote: false,
        total: 0,
        favor: 0,
        against: 0,
        abstention: 0,
        novote: 0,
        status: pointStatus,
        notes: null,
        votes: [],
        blocked_users: [],
        point_status: pointStatus,
        declaration,
      };

      const info = { id, meeting_point_status: meetingPointStatus, infoPoint: activeInfo };

      socketNsp.emit('update_meeting_point', JSON.stringify(info));
      dispatch(
        updateReunionPointInfo({ meeting_point_status: meetingPointStatus, infoPoint: activeInfo })
      );

      const promiseUpdate = new Promise(resolve => {
        dispatch(updateMeetingPointStatus(id, active.id, meetingPointStatus, resolve));
      });

      return promiseUpdate.then(() => {
        setVoting(false);
        setVotes([]);
        setQualityVoteUser('');
        setActive(c => ({ ...c, meeting_point_status: meetingPointStatus }));
        // getNextActiveInfo(activeInfo, true);
      });
    },
    [
      pointStatus,
      // getNextActiveInfo,
      toggleOpenPointStatus,
      id,
      active.id,
      active.meeting_point_status,
      activeInfo,
      dispatch,
      socketNsp,
    ]
  );

  const handleChangeActive = e => {
    if (e) e.preventDefault();

    const { idxTheme, idxProposal, type } = selected;

    setActive(selected);

    const newActiveInfo = {
      idxProposal,
      idxTheme,
      type,
    };

    setActiveInfo(newActiveInfo);

    window.scrollTo(0, 0);

    return setSelected({});
  };

  useEffect(() => {
    if (!selected.skipPrepareDiscussion) return;

    const { idxTheme, idxProposal, type } = selected;
    const newActiveInfo = {
      idxProposal,
      idxTheme,
      type,
    };

    setActive(selected);
    setActiveInfo(newActiveInfo);
    setSelected({});

    window.scrollTo(0, 0);
  }, [selected, setActive, setSelected]);

  useEffect(() => {
    if (!selected.skipPrepareVote) return;

    const { idxTheme, idxProposal, type } = selected;
    const newActiveInfo = {
      idxProposal,
      idxTheme,
      type,
    };

    setActive(selected);
    setActiveInfo(newActiveInfo);
    setSelected({});

    window.scrollTo(0, 0);

    setAllVote('');
    toggleOpenBegin();
  }, [selected, setSelected, toggleOpenBegin]);

  const clickVote = unanimousVote => e => {
    e.preventDefault();
    setAllVote(unanimousVote);
    const auxVotes = participantsInfo.voters.reduce((acc, cur) => {
      const objUserVote = votes.find(x => x.id === cur.id) || {};

      if (isEmpty(objUserVote.vote)) {
        // @ Utilizador não votou, assume o unânime
        acc.push({ id: cur.id, vote: unanimousVote, group_id: cur.group_id });
      } else {
        acc.push({ id: cur.id, vote: objUserVote.vote, group_id: cur.group_id });
      }

      return acc;
    }, []);

    setVotes(auxVotes);

    socketNsp.emit('update_all_votes', JSON.stringify({ id, votes: auxVotes }));
  };

  const handleQualityVote = useCallback(
    e => {
      e.preventDefault();
      toggleOpenVoteTie();
      setOpenQualityVote(true);
    },
    [toggleOpenVoteTie]
  );

  const showBiggerLabel = useMemo(() => {
    const nextInfo = getNextActiveInfo(activeInfo, false);
    if (nextInfo.length > 0) {
      // @ Existem pontos por discutir
      return true;
    }

    return false;
  }, [getNextActiveInfo, activeInfo]);

  const changeSecretary = newSecretary => {
    dispatch(updateMeetingSecretary(id, newSecretary));
  };

  const onUpdatePublicDiscussionTime = timerState => {
    dispatch(updatePublicDiscussionTimer(timerState));

    socketNsp.emit(
      'update_public_discussion_timer',
      JSON.stringify({
        id,
        publicDiscussion: timerState,
      })
    );
  };

  const onUpdateBeforeDayOrderTime = timerState => {
    dispatch(updateBeforeDayOrderTimer(timerState));

    socketNsp.emit(
      'update_before_day_order_timer',
      JSON.stringify({
        id,
        beforeDayOrder: timerState,
      })
    );
  };

  const onUpdatePointDiscussionTime = (pointId, timerType) => timerState => {
    dispatch(updatePointDiscussionTimer(pointId, timerType, timerState));

    socketNsp.emit(
      'update_point_discussion_timer',
      JSON.stringify({
        id,
        pointId,
        timerType,
        timerState,
      })
    );
  };

  const getActiveDiscussionPointTimerId = () =>
    active.proposal_id !== null ? `proposal-${active.id}` : `point-${active.id}`;

  const getActiveDiscussionPointTimer = type => {
    const timerId = getActiveDiscussionPointTimerId();

    return discussionPointsTimers[timerId][type];
  };

  const handleReunionStart = () => {
    const timerState = {
      ...meetingTimer,
      state: 'running',
    };

    dispatch(updateMeetingTimer(timerState));

    socketNsp.emit(
      'update_meeting_timer',
      JSON.stringify({
        id,
        meeting: timerState,
      })
    );
  };

  return (
    <>
      {!reunionEnd && <Prompt when={change} message={changePage} />}
      <Title
        title={name}
        moderator
        reunionEnd={reunionEnd}
        endReunion={endReunion}
        showAudience={openAudienceWindow}
        disabled={!isEmpty(infoSpeaking)}
        startReunion={handleReunionStart}
        reunionOngoing={meetingTimer.state !== 'stop'}
      />
      <Grid container spacing={3}>
        <Grid item xs={7} />
        <Grid item xs={4}>
          <Creatable
            label="Secretário atual"
            options={board.secretary.map(secretary => {
              return {
                label: secretary.name,
                name: secretary.name,
                value: secretary.id,
                id: secretary.id,
              };
            })}
            value={getCurrentSecretary(board)}
            handleChange={changeSecretary}
            noOptionsMessage={() => 'Não disponível'}
            placeholder="Selecionar secretário"
            getNewOptionData={(inputValue, optionLabel) => ({
              value: inputValue,
              name: inputValue,
              label: optionLabel,
              isNew: true,
            })}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.marginContainers}>
        <Grid item xs={1} />
        <ThemesAndPoints
          voting={voting}
          themes={themes}
          points={points}
          activeInfo={activeInfo}
          handleInfo={handleInfo}
          handleImmediateVote={
            isMainModerator
              ? (...args) =>
                () => {
                  handleImmediateVote(...args, true)();
                }
              : null
          }
          handleImmediateDiscussion={
            isMainModerator
              ? (...args) =>
                () => {
                  handleImmediateDiscussion(...args, true)();
                }
              : null
          }
          isPublic={Boolean(isPublic)}
          moderator
        />
        <Grid item xs={4}>
          {!isEmpty(active.type) &&
            (active.type === 'public-discussion' || active.type === 'before-day-order') && (
              <>
                <Body2 secondary className={classes.marginHeader}>
                  {!isEmpty(active.type)
                    ? active.type === 'before-day-order'
                      ? 'Tempo do período ativo'
                      : 'Tempo de fala do interveniente'
                    : 'Tempo da proposta ativa'}
                </Body2>
                {active.type === 'public-discussion' && (
                  <TimeTracker
                    isMainModerator={isMainModerator}
                    timer={publicDiscussionTimer}
                    onTimeChange={onUpdatePublicDiscussionTime}
                  />
                )}
                {active.type === 'before-day-order' && (
                  <TimeTracker
                    isMainModerator={isMainModerator}
                    timer={beforeDayOrderTimer}
                    onTimeChange={onUpdateBeforeDayOrderTime}
                  />
                )}
              </>
            )}
          {!isEmpty(active) &&
            active.type !== 'public-discussion' &&
            active.type !== 'before-day-order' && (
              <>
                <Body2 secondary className={classes.marginHeader}>
                  Período de apresentação da proposta
                </Body2>
                <TimeTracker
                  isMainModerator={isMainModerator}
                  timer={getActiveDiscussionPointTimer('presentation')}
                  onTimeChange={onUpdatePointDiscussionTime(
                    getActiveDiscussionPointTimerId(),
                    'presentation'
                  )}
                />
                <Body2 secondary className={classes.marginHeader}>
                  Período de protestos / defesa de honra / reações
                </Body2>
                <TimeTracker
                  isMainModerator={isMainModerator}
                  timer={getActiveDiscussionPointTimer('protests')}
                  onTimeChange={onUpdatePointDiscussionTime(
                    getActiveDiscussionPointTimerId(),
                    'protests'
                  )}
                />
              </>
            )}
          <Body2 secondary className={classes.marginHeader}>
            {!isEmpty(active.type) ? 'Período ativo' : 'Proposta ativa'}
          </Body2>
          {!isEmpty(active) ? (
            <ActiveProposal
              active={active}
              isProposal={isProposal}
              beginVote={beginVote}
              disabled={!isEmpty(infoSpeaking)}
              voting={voting}
              activeInfo={activeInfo}
              reunionEnd={reunionEnd}
              activeHasStatus={activeHasStatus}
              moderator={isMainModerator}
            />
          ) : (
            <NoActiveProposal />
          )}
          {!isEmpty(selected) && (
            <ViewPoint
              selected={selected}
              handleChangeActive={handleChangeActive}
              handleInfo={handleInfo}
              isSelectedPoint={isSelectedPoint}
              idxProposal={selected.idx}
              moderator={isMainModerator}
              reunionEnd={reunionEnd}
              users={users}
              isGroup={Boolean(is_group)}
              disabled={!isEmpty(infoSpeaking)}
            />
          )}
        </Grid>
        {activeInfo?.type !== 'public-discussion' && (
          <Grid item xs={3}>
            {!voting && !activeHasStatus ? (
              <>
                <Body2 secondary className={classes.marginHeader}>
                  Participante{participants.length > 1 ? 's' : ''}
                </Body2>
                <Paper>
                  {speakers && speakers.length > 0 && !voting && Boolean(is_group) && (
                    <>
                      {!isEmpty(activeInfo.type) && activeInfo.type === 'public-discussion' ? (
                        <PublicDiscussionTimer
                          current={currentPublic}
                          reunionEnd={false}
                          infoSpeaking={infoSpeaking}
                          activeInfo={activeInfo}
                          moderator
                        />
                      ) : (
                        <>
                          <GroupInterventions
                            current={interventions}
                            moderator
                            reunionEnd={false}
                            infoSpeaking={infoSpeaking}
                            activeInfo={activeInfo}
                          />
                          {isEmpty(active.matrix) || active?.matrix?.is_group ? (
                            <GroupUsersTimer
                              groups={speakers}
                              participants={participants}
                              infoSpeaking={infoSpeaking}
                              hasMatrix={active && !isEmpty(active.matrix)}
                              activeInfo={activeInfo}
                              activePointId={active && active.proposal_id ? active.id : null}
                              moderator
                            />
                          ) : (
                            <IndividualUsersTimer
                              users={speakers}
                              participants={participants}
                              infoSpeaking={infoSpeaking}
                              hasMatrix={active && !isEmpty(active.matrix)}
                              activeInfo={activeInfo}
                              activePointId={active && active.proposal_id ? active.id : null}
                              moderator
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                  {speakers && speakers.length > 0 && !voting && !is_group && (
                    <>
                      <IndividualInterventions
                        current={interventions}
                        moderator
                        reunionEnd={false}
                        infoSpeaking={infoSpeaking}
                        activeInfo={activeInfo}
                      />
                      <IndividualUsersTimer
                        users={speakers}
                        participants={participants}
                        infoSpeaking={infoSpeaking}
                        hasMatrix={active && !isEmpty(active.matrix)}
                        activeInfo={activeInfo}
                        activePointId={active && active.proposal_id ? active.id : null}
                        moderator
                      />
                    </>
                  )}
                </Paper>
              </>
            ) : activeHasStatus ? (
              <>
                <Body2 secondary className={classes.marginHeader}>
                  Estado da proposta
                </Body2>
                <Paper>
                  <ProposalStatusInfo
                    pointStatus={active.meeting_point_status}
                    users={users}
                    isGroup={Boolean(is_group)}
                  />
                </Paper>
              </>
            ) : null}
            {voting && (
              <>
                {typeVote === 'secret' && (
                  <>
                    <Body2 secondary className={classes.marginHeader}>
                      Votação
                    </Body2>
                    <Body2 className={classes.secretLabel}>Esta votação é secreta.</Body2>
                    <Subtitle1 component="p" className={classes.secretLabel}>
                      Número de participantes: {participantsInfo.meetingParticipants.length}
                    </Subtitle1>
                    <Subtitle1 component="p" className={classes.secretLabel}>
                      Número de participantes presentes: {participantsInfo.voters.length}
                    </Subtitle1>
                    <Subtitle1 component="p" className={classes.secretSubLabel}>
                      Número de votos contabilizados: {getTotalSecretVotes(secretVotes)}
                    </Subtitle1>
                    <SecretVotesCounter secretVotes={secretVotes} changeVote={updateSecretVotes} />
                  </>
                )}
                {typeVote === 'public' && (
                  <>
                    <Box className={classes.publicVoteBox}>
                      <Grid container>
                        <Grid item xs={8} className={classes.flexCenter}>
                          <Body2 secondary>Votação</Body2>
                        </Grid>
                        <Grid item xs={4} className={classes.unanimousVote}>
                          <Subtitle2>Voto unânime</Subtitle2>
                          <Box
                            className={clsx(classes.unanimousBox, {
                              [classes.disabled]: allVote !== '' || activeHasStatus,
                            })}
                          >
                            <FavorVoteIcon
                              className={clsx(classes.iconUnanimous, {
                                [classes.favor]: allVote === 'favor',
                              })}
                              onClick={clickVote('favor')}
                            />
                            <AbstentionVoteIcon
                              className={clsx(classes.iconUnanimous, {
                                [classes.abstention]: allVote === 'abstention',
                              })}
                              onClick={clickVote('abstention')}
                            />
                            <AgainstVoteIcon
                              className={clsx(classes.iconUnanimous, {
                                [classes.against]: allVote === 'against',
                              })}
                              onClick={clickVote('against')}
                            />
                            <NoVoteIcon
                              className={clsx(classes.iconUnanimous, {
                                [classes.noVote]: allVote === 'novote',
                              })}
                              onClick={clickVote('novote')}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      {/* TODO: validar aqui se está apenas para grupos */}
                      <PublicVoteDisplay
                        groups={speakers}
                        participantsInfo={participantsInfo}
                        votes={votes}
                        setVotes={setVotes}
                        isGroup={Boolean(is_group)}
                        hasMatrix={active && !isEmpty(active.matrix)}
                        isGroupMatrix={active?.matrix?.is_group}
                        moderator
                      />
                    </Box>
                    {hasDeclaration && (
                      <UsersDeclaration
                        users={users}
                        declaration={active.meeting_point_status.declaration}
                        isGroup={Boolean(is_group)}
                      />
                    )}
                  </>
                )}
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.endVote}
                  onClick={toggleOpenEnd}
                  disabled={notValidSecretVoting}
                >
                  Encerrar votação
                </Button>
                <Box height="15px" />
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.cancelVote}
                  onClick={e => {
                    e.preventDefault();
                    setOpenCancelVote(true);
                  }}
                  disabled={notValidSecretVoting}
                >
                  Cancelar votação
                </Button>
                {notValidSecretVoting && (
                  <Subtitle1 component="p" className={classes.errorVoting}>
                    Existem {participantsInfo.voters.length} participantes presentes e foram
                    contabilizados {getTotalSecretVotes(secretVotes)} votos.
                  </Subtitle1>
                )}
              </>
            )}
            {isProposal && !activeHasStatus && (
              <BoxUpdatePointStatus
                handlePointStatus={handlePointStatus}
                disabled={!isEmpty(infoSpeaking)}
              />
            )}
          </Grid>
        )}
        <Grid item xs={1} />
      </Grid>
      <DialogCheck
        open={openEnd}
        msg="Tem a certeza que deseja encerrar a votação?"
        handleCancel={toggleOpenEnd}
        handleSubmit={typeVote === 'secret' ? endSecretVote : endNormalVote}
        labelSubmit="Encerrar"
      />
      <DialogCheck
        open={openVoteTie}
        msg={`Empate na votação, repetir votação${canUseQualityVote ? ' ou exercer voto de qualidade' : ''
          }?`}
        handleCancel={canUseQualityVote ? handleQualityVote : toggleOpenVoteTie}
        handleSubmit={confirmRepeat}
        labelSubmit="Repetir votação"
        labelCancel={canUseQualityVote ? 'Exercer voto de qualidade' : 'Sair'}
        biggerLabel
        disableBackdropClick
      >
        {typeVote === 'public' && qualityVoters.length === 0 && (
          <>
            <Body2 className={classes.labelHelper}>
              Não é possível exercer voto de qualidade porque nenhum participante da reunião tem
              essa permissão atribuída.
            </Body2>
          </>
        )}
      </DialogCheck>
      <DialogPointStatus
        pointName={active.name}
        open={openPointStatus}
        pointStatus={pointStatus}
        handleCancel={toggleOpenPointStatus}
        handleSubmit={updatePointStatus}
      />
      <DialogCheck
        open={openEndReunion}
        msg={
          showBiggerLabel
            ? 'Existem pontos por discutir. Deseja, mesmo assim, encerrar a reunião?'
            : 'Tem a certeza que deseja encerrar a reunião?'
        }
        handleCancel={e => {
          e.preventDefault();
          setOpenEndReunion(false);
        }}
        biggerLabel={showBiggerLabel}
        handleSubmit={confirmEnd}
        labelSubmit="Encerrar"
      >
        {/* {undiscussedPoints} */}
      </DialogCheck>
      <DialogCheck
        open={openWarning}
        msg="Pretende sair da reunião?"
        handleCancel={cancelLeaving}
        handleSubmit={confirmChange}
        labelSubmit="Sair"
      />
      <DialogCheck
        open={openCancelVote}
        msg="Pretende cancelar a votação? A proposta continuará disponível para discussão."
        handleCancel={e => {
          e.preventDefault();
          setOpenCancelVote(false);
        }}
        handleSubmit={confirmCancelVote}
        labelSubmit="Cancelar votação"
        labelCancel="Sair"
      />
      <DialogCheck
        open={openQualityVote}
        msg="Voto de qualidade"
        biggerLabel
        handleCancel={e => {
          e.preventDefault();
          setOpenQualityVote(false);
          setQualityVoteUser('');
        }}
        handleSubmit={endNormalVote}
        labelSubmit="Confirmar voto de qualidade"
        disableBackdropClick
      >
        <QualityVoteContent
          qualityVoters={qualityVoters}
          qualityVoteUser={qualityVoteUser}
          qualityVoteType={qualityVoteType}
          setQualityVoteType={setQualityVoteType}
          setQualityVoteUser={setQualityVoteUser}
        />
      </DialogCheck>
      <DialogVoteType open={openBegin} voteType={voteType} onClose={toggleOpenBegin} />
    </>
  );
};

MeetingModerator.propTypes = {
  change: PropTypes.bool.isRequired,
};

export default MeetingModerator;
