import React from 'react';
import { makeStyles, Dialog, DialogContent, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body1 from '../../../../common/typographys/Body1';
import Body2 from '../../../../common/typographys/Body2';
import hasOnlyOne from '../../../../../utils/hasOnlyOne';
// import InputField from '../../../../common/forms/InputField';

const useStyles = makeStyles(() => ({
  content: {
    padding: '30px',
  },
  boxButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  buttonConfirm: {
    marginLeft: '30px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttonCancel: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  marginName: {
    marginBottom: '30px',
  },
}));

const DialogPointStatus = ({ open, active, pointStatus, handleCancel, handleSubmit }) => {
  const classes = useStyles();
  let hintText;
  // const [notes, setNotes] = useState('');

  // useEffect(() => {
  //   if (!open) {
  //     setNotes('');
  //   }
  // }, [open]);

  if (hasOnlyOne(active)) {
    const pointName = active[0].name;
    hintText = `Deseja ${pointStatus[0] === 3 ? 'retirar' : 'adiar'} a proposta ${
      pointName ? `${pointName}` : ''
    }?`;
  } else if (active?.length > 0) {
    hintText = `Deseja ${pointStatus[0] === 3 ? 'retirar' : 'adiar'}?`;
  } else {
    return null;
  }

  const buttonLabel = pointStatus[0] === 3 ? 'Retirar' : 'Adiar';

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleCancel}
      // onClose={e => {
      //   setNotes('');
      //   handleCancel(e);
      // }}
    >
      <DialogContent className={classes.content}>
        <Body1 className={classes.marginName}>{hintText}</Body1>
        {/* <InputField
          multiline
          value={notes}
          rows="1"
          rowsMax="4"
          label="Notas"
          placeholder="Adicione notas sobre a decisão (opcional)"
          name="notes"
          onChange={e => setNotes(e.target.value)}
        /> */}
        <Box className={classes.boxButtons}>
          <Body2 onClick={handleCancel} className={classes.buttonCancel}>
            Cancelar
          </Body2>
          <Body2 secondary className={classes.buttonConfirm} onClick={handleSubmit}>
            {buttonLabel}
          </Body2>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

DialogPointStatus.defaultProps = {
  active: [],
};

DialogPointStatus.propTypes = {
  open: PropTypes.bool.isRequired,
  pointStatus: PropTypes.number.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  active: PropTypes.array,
};

export default React.memo(DialogPointStatus);
