import React, { useEffect, useContext, useCallback, useState, useMemo } from 'react';
import SeeItem from '../../pieces/SeeItem';
import PropTypes from 'prop-types';
import LabelAndValue from '../../../common/forms/LabelAndValue';
import { EntityDispatchContext } from './CreateEditEntityProvider';
import EditEntity from './EditEntity';
import { ConfigStateContext, ConfigDispatchContext } from '../../MenuConfigProvider';
import { useDispatch, useSelector } from 'react-redux';
import { getEntityProposals } from '../../../../store/actions/entitiesActions';
import { makeStyles, Box } from '@material-ui/core';
import H6 from '../../../common/typographys/H6';
import Body1 from '../../../common/typographys/Body1';
import EntityProposals from './EntityProposals';
import subMonths from 'date-fns/subMonths';
import BoxDateFilter from '../../../proposals/pieces/BoxDateFilter';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import getDateObj from '../../../../utils/getDateObj';
import formatDate from '../../../../utils/formatDate';
import { downloadExcel } from '../../../../store/actions/usersActions';

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '8px',
  },
}));

const attributes = ['Identificador', 'Nome da proposta', 'Valor', 'Data de submissão', 'Aprovada'];

const SeeEditEntity = ({ info }) => {
  const classes = useStyles();
  const dispatchConfig = useContext(ConfigDispatchContext);
  const dispatch = useContext(EntityDispatchContext);
  const dispatchRedux = useDispatch();
  const { editing } = useContext(ConfigStateContext);
  const { proposals } = useSelector(state => state.entities);
  const [search, setSearch] = useState('');
  const [date, setDate] = useState(subMonths(new Date(), 5));
  const [endDate, setEndDate] = useState(new Date());

  const updateField = useCallback(
    (name, value) => {
      dispatch({
        type: 'UPDATE_FIELD',
        payload: {
          name,
          value,
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    updateField('name', info.name);
  }, [info.name, updateField]);

  useEffect(() => {
    updateField('nif', info.nif);
  }, [info.nif, updateField]);

  useEffect(() => {
    if (info.isItemEntity) {
      updateField('id', info.id);
      if (info.id) {
        dispatchRedux(getEntityProposals(info.id));
      } else {
        dispatchRedux({
          type: 'GET_ENTITY_PROPOSALS',
          payload: [],
        });
      }
    }
  }, [info.id, dispatchRedux, updateField]);

  useEffect(() => {
    updateField('type', info.type);
  }, [info.type, updateField]);

  useEffect(() => {
    updateField('email', info.email);
  }, [info.email, updateField]);

  useEffect(() => {
    updateField('contact', info.contact);
  }, [info.contact, updateField]);

  const startEdit = useCallback(
    e => {
      e.preventDefault();
      dispatchConfig({
        type: 'SET_EDITING',
      });
    },
    [dispatchConfig]
  );

  const proposalsToShow = useMemo(() => {
    if (search !== '') {
      return proposals
        .filter(
          x =>
            x.name.toLowerCase().includes(search.toLowerCase()) ||
            x.identifier_code.toLowerCase().includes(search.toLowerCase())
        )
        .filter(
          x =>
            isAfter(getDateObj(x.date), startOfDay(date)) &&
            isBefore(getDateObj(x.date), endOfDay(endDate))
        );
    }

    return proposals.filter(
      x =>
        isAfter(getDateObj(x.date), startOfDay(date)) &&
        isBefore(getDateObj(x.date), endOfDay(endDate))
    );
  }, [proposals, search, date, endDate]);

  const exportToExcel = useCallback(
    e => {
      e.preventDefault();

      // @ ['Identificador', 'Nome da proposta', 'Valor', 'Data de submissão', 'Aprovada']
      const infoExport = {
        attributes,
        data: proposalsToShow.map(
          ({ identifier_code, name, amount, date: dateProposal, approved }) => [
            identifier_code || '',
            name,
            amount,
            formatDate(dateProposal),
            approved ? 'Sim' : 'Não',
          ]
        ),
      };

      dispatchRedux(downloadExcel(infoExport, `Propostas-${info.name}.xlsx`));
    },
    [proposalsToShow, dispatchRedux, info.name]
  );

  return editing ? (
    <EditEntity />
  ) : (
    <SeeItem submitEdit={startEdit}>
      <LabelAndValue label="Nome" value={info.name} />
      <LabelAndValue label="Email" value={info.email} />
      <LabelAndValue label="NIF" value={info.nif} />
      <LabelAndValue label="Valor candidatado a propostas" value={`${info.total_amount} €`} />
      <LabelAndValue label="Valor aprovado em reuniões" value={`${info.approved_amount} €`} />
      {/* <LabelAndValue label="Tipo de entidade" value={info.type} /> */}
      <LabelAndValue label="Contacto" value={info.contact} />
      <H6 className={classes.label}>Propostas submetidas</H6>
      {proposals.length === 0 ? (
        <Body1>Nenhuma proposta submetida</Body1>
      ) : (
        <>
          <Box height="10px" />
          <BoxDateFilter
            hideFilter
            endDate={endDate}
            date={date}
            setDate={setDate}
            setEndDate={setEndDate}
            exportToExcel={exportToExcel}
            showExport
          />
          <Box height="10px" />
          <EntityProposals proposals={proposalsToShow} search={search} setSearch={setSearch} />
        </>
      )}
    </SeeItem>
  );
};

SeeEditEntity.propTypes = {
  info: PropTypes.object.isRequired,
};

export default React.memo(SeeEditEntity);
