import React from 'react';
import { Box, Avatar, Grid } from '@material-ui/core';
import loginRegisterStyles from '../../../common/layout/styles/loginRegister';
// import logo from '../../../assets/images/logoAutarquia.svg';
import logo from '../../../../assets/images/logo.png';
import Footer from '../../../common/layout/Footer';
import ConfigLinks from '../../../common/ConfigLinks';
import ConfigDescription from '../../../common/ConfigDescription';
import clsx from 'clsx';

const Info = () => {
  const classes = loginRegisterStyles();

  return (
    <>
      <Grid container>
        <Box width="100%" display="flex" justifyContent="center" className={classes.boxAvatar}>
          <Avatar
            src={logo}
            alt="Câmara Municipal de Gondomar"
            className={classes.avatar}
            classes={{
              img: classes.imgAvatar,
            }}
          />
        </Box>
        <Grid item sm={8} xs={10} className={classes.marginHorizontal}>
          <ConfigDescription />
        </Grid>
      </Grid>
      <Grid container className={clsx([classes.marginTop, classes.footerInfo])}>
        <Box width="100%">
          <Grid item>
            <ConfigLinks />
          </Grid>
          <Grid item xl={11} style={{ margin: '0 auto' }}>
            <Footer login />
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default React.memo(Info);
